import { Slider } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import * as ParameterStyles from "src/shared/StyledComponents/Parameters";
import { isQuotePresent } from "src/utils";

import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors } from "src/redux/selectors";

interface SolarModuleInputProps {
  openSolarMessageWindow: () => void;
}
const SolarModuleInput: React.FC<SolarModuleInputProps> = (props: SolarModuleInputProps) => {
  const { openSolarMessageWindow } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { quantity } = useSelector(ProjectSelectors.getSolarPanel);

  const [maximumPanel, setMaximumPanel] = useState(71);

  const quote = useSelector(ProjectSelectors.getQuote);

  const solarSliderChangeHandler = (value: number): void => {
    const adjustedValue = Math.max(6, value);
    openSolarMessageWindow();
    const action = ProjectActions.updateSolarPanel({
      quantity: adjustedValue,
    });
    dispatch(action);
  };

  useEffect(() => {
    if (isQuotePresent(quote)) {
      const greenQuoteModuleQty = quote.profiles.green.moduleQuantity;
      setMaximumPanel(greenQuoteModuleQty);
    }
  }, [dispatch, quote]);

  return (
    <ParameterStyles.Line hasSlider isPadding>
      <ParameterStyles.Label hasSlider bold>
        <div>{t("SOLAR MODULES")}</div> 
      </ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider
          defaultValue={Math.round(quantity)}
          onChange={solarSliderChangeHandler}
          value={Math.round(quantity)}
          max={Math.round(quote.profiles.green.moduleQuantity)}
        />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>{Math.round(quote.profiles.green.moduleQuantity)}</ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default SolarModuleInput;
