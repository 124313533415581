import { ProductFilterKeys, ProductFilters } from "src/hooks";

import { ProductDB, ProductProperties } from "src/db-types";
import { ValueOf, getNumber } from "src/utils";

import { filterInStock } from "./filterInStock";
import { sortProductsByMaxCapacity } from "./sortByMaxCapacity";

type FilterEntriesArray = Array<[ProductFilterKeys, ValueOf<ProductFilters>]>;

interface Props {
  products: ProductDB[];
  filters: ProductFilters;
}
export const filterProducts = (props: Props): ProductDB[] => {
  const { products, filters } = props;

  let filteredProducts = filterInStock(products);

  // If hybrid is enabled, then first filter products with hybrid
  if (filters.hybrid) {
    filteredProducts = filteredProducts.filter((prod) =>
      filterProductPredicate({
        expectedValue: filters.hybrid,
        key: "hybrid",
        product: prod,
      }),
    );
  }

  // Now applicable max capacity
  const isMaxCapacitySet = isFilterSet(filters.applicableMaxCapacity);
  if (isMaxCapacitySet) {
    filteredProducts = getProductsInCapacityRange({
      products: filteredProducts,
      maxCapacity: getNumber(filters.applicableMaxCapacity),
      rangeForCapacity: products[0]?.type === "inverter" ? 3000 : 1000,
    });
  }

  // Now all the other filters
  const filtered = filteredProducts.filter((product) => {
    const entries = Object.entries(filters) as FilterEntriesArray;

    return entries.every(([filterKey, filterValue]) => {
      const result = filterProductPredicate({
        expectedValue: filterValue,
        product,
        key: filterKey,
      });

      return result;
    });
  });

  return filtered;
};

interface TestFilterProps {
  product: ProductDB;
  key: keyof ProductFilters;
  expectedValue: ValueOf<ProductFilters>;
}
export const filterProductPredicate = (props: TestFilterProps): boolean => {
  const { product, key, expectedValue } = props;

  if (isFilterNotSet(expectedValue)) return true;

  if (key === "applicableMaxCapacity") {
    const result = doesProductHaveCapacity({
      product,
      requiredInWattPerHour: expectedValue as number,
    });
    if (!result) return false;
  } else {
    const result = doesProductMatchesProperty({
      product,
      expectedValue,
      propertyKey: key,
    });
    if (!result) return false;
  }

  return true;
};

interface CapacityProps {
  requiredInWattPerHour: number;
  product: ProductDB;
}
export const doesProductHaveCapacity = (props: CapacityProps): boolean => {
  const { product, requiredInWattPerHour } = props;
  const { applicableMaxCapacity } = product;

  if (!applicableMaxCapacity) return false;

  if (applicableMaxCapacity < requiredInWattPerHour) return false;

  return true;
};

interface PropertyCheckProps {
  product: ProductDB;
  propertyKey: keyof ProductProperties;
  expectedValue: ValueOf<ProductFilters>;
}
export const doesProductMatchesProperty = (props: PropertyCheckProps): boolean => {
  const { product, propertyKey, expectedValue } = props;

  const { properties } = product;

  const propertyValue = properties?.[propertyKey];

  if (propertyValue !== expectedValue) {
    return false;
  }

  return true;
};

interface BatteriesInMaxCapacityRangeProps {
  products: ProductDB[];
  maxCapacity: number;
  rangeForCapacity?: number;
}
export const getProductsInCapacityRange = (
  props: BatteriesInMaxCapacityRangeProps,
): ProductDB[] => {
  const { maxCapacity, products, rangeForCapacity = 1000 } = props;

  const lowerLimit = maxCapacity;
  const upperLimit = maxCapacity + rangeForCapacity;

  const inRangeOf1000s = products.filter((product) => {
    const productCapacity = getNumber(product.applicableMaxCapacity);
    const productMinCapactiy =
      getNumber(product.minimalCapacity) || (products[0]?.type === "inverter" ? 3000 : 1000);
    return productMinCapactiy <= maxCapacity && productCapacity >= upperLimit;
  });

  if (inRangeOf1000s.length > 0) return inRangeOf1000s.slice();

  const closestProduct = sortProductsByMaxCapacity(products).find(
    (p) => getNumber(p.applicableMaxCapacity) >= lowerLimit,
  );
  if (closestProduct) return [closestProduct];

  return [];
};

export const isFilterSet = (filterValue: ValueOf<ProductFilters>): boolean => {
  return filterValue !== null && filterValue !== false;
};

export const isFilterNotSet = (filterValue: ValueOf<ProductFilters>): boolean => {
  return !isFilterSet(filterValue);
};
