import { useRef, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useAppSelector } from "src/hooks";

import { getAverageCapacity } from "src/helpers";
import { useSaveMedia } from "src/hooks/apis";
import CreateProto1 from "src/Viewer.jsx";




import "./styles.css";

import { FontLoader } from "three/examples/jsm/loaders/FontLoader";

// import { ProductSelectors } from "src/redux/selectors";
import { ProjectActions } from "src/redux/actionCreators";
import { getAdminGeneratedBakeModelUrls } from "src/redux/project/selectors";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";

import { Button, SxProps } from "@mui/material";


const selectedButtonStyles: SxProps = {
  backgroundColor: "#FFC107",
  border: "1px solid #FFC107",
  borderRadius: "5px",
  color: "white",
  "&:hover": {
    backgroundColor: "#FFC107",
    color: "white",
    border: "1px solid #FFC107",
  },
};

const notSelectedButtonStyles: SxProps = {
  border: "1px solid #FFC107",
  borderRadius: "5px",
  color: "#FFC107",
  "&:hover": {
    backgroundColor: "#FFC107",
    color: "white",
    border: "1px solid #FFC107",
  },
  "&.Mui-disabled": {
    border: "1px solid gray",
    cursor: "not-allowed",
  },
};


// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Common3dViewer = ({
  from3DViwer,
  maxWidth3DViewer,
  maxWidth3DViewerWithImages,
  modelFullView,
  setModelFullView,
}: {
  from3DViwer: boolean;
  maxWidth3DViewer: boolean;
  maxWidth3DViewerWithImages: boolean;
  modelFullView?: boolean;
  setModelFullView?: (value: boolean) => void;
}) => {
  const [font, setFont] = useState<any>(null);
  const element: any = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const threejsRef: any = useRef(null);
  const quoteProfile = useSelector(ProjectSelectors.getQuoteProfile);
  const quote = useSelector(ProjectSelectors.getQuoteProfile);
  const address = useSelector(ProjectSelectors.getAddress);
  const quoteDetails = useSelector(ProjectSelectors.getQuote);
  const quoteId = useSelector(ProjectSelectors.getQuoteId);
  const project = useSelector(ProjectSelectors.getProject);
  const solarPanelProduct = useSelector(ProjectSelectors.getSolarPanel);
  const filteredSolarPanels = useAppSelector(ProductSelectors.getFilteredSolarPanels);
  let capacity = getAverageCapacity(filteredSolarPanels);
  if (solarPanelProduct?.item) {
    capacity = Number(solarPanelProduct?.item?.capacity);
  }
  const bakeModelImages = useSelector(ProjectSelectors.getBakeModelImages);
  // const { t } = useTranslation();
  const popupRef: any = useRef(null);
  const { fetchData } = useSaveMedia();
  const selectedInstallers = useSelector(ProjectSelectors.getSelectedInstaller);
  const productObjFiles = useSelector(ProductSelectors.getAllProductObjFiles);
  const adminGeneratedBakeModelUrls = useSelector(ProjectSelectors.getAdminGeneratedBakeModelUrls);
  const adminGeneratedImageUrls = useSelector(ProjectSelectors.getAdminGeneratedImageurls);

  const dispatch = useDispatch();
  const installerUploadedObj = selectedInstallers[0]?.files?.find((a) => a.endsWith(".obj"));
  const installerUploadedMtl = selectedInstallers[0]?.files?.find(
    (a) => a.includes(".obj.mtl") || a.includes(".mtl"),
  );
  const threeDModels = useSelector(ProjectSelectors.getThreeDModels);

  const transformedUrls = useMemo(() => {
    const updatedUrls = adminGeneratedBakeModelUrls?.map((rawUrl: string) => {
      const [, , bucket, ...objectKeyParts] = rawUrl.split("/");
      const objectKey = objectKeyParts.join("/");

      return `https://${bucket.replace(".s3.", "")}.s3.eu-central-1.amazonaws.com/${objectKey}`;
    });
    return updatedUrls;
  }, [getAdminGeneratedBakeModelUrls]);

  const imageUrls = useMemo(() => {
    const updatedUrls = adminGeneratedImageUrls?.map((rawUrl: string) => {
      const [, , bucket, ...objectKeyParts] = rawUrl.split("/");
      const objectKey = objectKeyParts.join("/");

      return `https://${bucket.replace(".s3.", "")}.s3.eu-central-1.amazonaws.com/${objectKey}`;
    });
    return updatedUrls;
  }, [adminGeneratedImageUrls]);

  // Don't remove due to dispatch present inside
  const threeDModelMemo = useMemo(() => {
    const modelUrls: any = {};
    let active: string | null = null;

    if (bakeModelImages) {
      // user generated model
      modelUrls.userGenerated = {
        mtl: bakeModelImages.find((img) => img.includes(".obj.mtl")),
        obj: bakeModelImages.find((img) => img.endsWith(".obj")),
        images:
          bakeModelImages.filter((img) => !img.includes(".mtl") && !img.includes(".obj")) || [],
      };
      active = "userGenerated";
    }

    if (installerUploadedObj) {
      // files uploaded by installer
      modelUrls.installerGenerated = {
        obj: installerUploadedObj,
        mtl: installerUploadedMtl,
        images:
          selectedInstallers[0]?.files?.filter(
            (img) => !img.includes(".obj") && !img.includes(".mtl"),
          ) || [],
      };
      active = active || "installerGenerated";
    }

    if (transformedUrls) {
      // for admin generated model
      const objUrl: any = transformedUrls.find((img) => img.endsWith(".obj"));
      const mtlUrl: any = transformedUrls.find((img) => img.includes(".mtl"));
      const imgUrls: any = transformedUrls.filter(
        (img) => !img.includes(".mtl") && !img.includes(".obj"),
      );

      modelUrls.adminGenerated = {
        obj: objUrl,
        mtl: mtlUrl,
        images: imgUrls,
      };
      active = active || "adminGenerated";
    }

    if (quote.imgURLs.length) {
      modelUrls.cksProvided = {
        // cks provided model
        mtl: quote.imgURLs.find((img) => img.includes(".mtl")),
        obj: quote.imgURLs.find((img) => img.includes(".obj")),
        images: quote.imgURLs.filter((img) => !img.includes(".mtl") && !img.includes(".obj")) || [],
      };
      active = active || "cksProvided";
    }

    dispatch(ProjectActions.updateThreeDModels({ active, models: modelUrls }));
    return { active, models: modelUrls };
  }, [quoteId]);

  const imgURLs: any = useMemo(() => {
    if (bakeModelImages) {
      // user generated model
      return {
        mtl: bakeModelImages.find((img) => img.includes(".obj.mtl")),
        obj: bakeModelImages.find((img) => img.includes(".obj")),
      };
    }

    if (installerUploadedObj) {
      // files uploaded by installer
      return {
        obj: installerUploadedObj,
        mtl: installerUploadedMtl,
      };
    }
    return {
      // cks provided model
      mtl: quote.imgURLs.find((img) => img.includes(".mtl")),
      obj: quote.imgURLs.find((img) => img.includes(".obj")),
    };
  }, [quote.imgURLs, bakeModelImages]);

  const totalModelsCount = useMemo(() => {
    return Object.keys(threeDModels?.models).length;
  }, [threeDModels?.models]);

  const loadFont = async (url: string) => {
    return await new Promise((resolve, reject) => {
      const loader = new FontLoader();
      loader.load(url, resolve, undefined, reject);
    });
  };

  useEffect(() => {
    const loadFontAsync = async () => {
      try {
        const loadedFont = await loadFont(
          "https://threejsfundamentals.org/threejs/resources/threejs/fonts/helvetiker_regular.typeface.json",
        );
        setFont(loadedFont);
      } catch (error) {
        console.error("Error loading font:", error);
      }
    };

    loadFontAsync();
  }, []);

  const activeModel = threeDModels?.active ? threeDModels.models[threeDModels.active] : null;
  const mtlUrl = activeModel?.mtl || "";
  const objUrl = activeModel?.obj || "";
  // console.log(threeDModels);


  useEffect(() => {
    if (!isInitialized && mtlUrl && objUrl) {
      setIsInitialized(true);

      // console.log("3D Viewer Initialized with:", mtlUrl, objUrl);

    }
  }, [isInitialized, productObjFiles]);


  return (
    <>
      <div className="content" ref={element}>
        <div className="flex items-center justify-between">
          <div className="flex gap-x-2">
            {totalModelsCount > 1 &&
              Object.keys(threeDModels?.models)?.map((model: string, index: number) => {
                return (
                  <Button
                    size="small"
                    sx={
                      threeDModels.active === model ? selectedButtonStyles : notSelectedButtonStyles
                    }
                  >
                    {index + 1}
                  </Button>
                );
              })}</div>
          {setModelFullView && (
            <img
              src={
                modelFullView
                  ? "/images/application_step_3/arrowLeft.svg"
                  : "/images/application_step_3/arrowRight.svg"
              }
              className="mb-1 hover:cursor-pointer"
              onClick={() => setModelFullView(!modelFullView)}
              alt=""
            />
          )}
        </div>

        {threeDModels?.active && quoteDetails?.id && address && (capacity === 0 || capacity) && (
          <div ref={threejsRef} className="threeD_loader_component" style={{ position: "relative" }}>
            <CreateProto1
              threejsRef={threejsRef}
              productObjFiles={productObjFiles}
              dispatch={dispatch}
              from3DViwer={from3DViwer}
              maxWidth3DViewer={maxWidth3DViewer}
              maxWidth3DViewerWithImages={maxWidth3DViewerWithImages}
              mtlUrl={mtlUrl}
              objUrl={objUrl}
              address={address}
              project={project}
              quoteProfile={quoteProfile}
              setModelFullView={setModelFullView}
              modelFullView={modelFullView}
              capacity={capacity}
            />
          </div>
        )}

      </div>
    </>
  );
};

export default Common3dViewer;
