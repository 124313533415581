import { Elements } from "@stripe/react-stripe-js";
import { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import { ApplicationLoader } from "src/pages/ApplicationPage/components";
import {
  AuthPopup,
  WelcomePopupNoAddress,
  ChartTabs,
  ConsumptionProfileDialog,
  SelectBuildingType,
  HubSpotBookCall,
  InstallerRequestSuccessfull,
  ProductPopup,
  PartnerCityConsulting,
  WebinarPopup,
  WebinarProjectDetails,
  ProductDetails,
  ProductInfoPopup,
  DataPolicy,
  PdfTemplateSelection,
} from "src/popups";
import { stripeLoader } from "src/utils";

import CarSelector from "./carselector/CarSelector";
import DetailedOffer from "./DetailedOffer/detailedOffer";
import MultiTenant from "./multiTenant/MultiTenant";
import CheckoutWithPayment from "./paywall/checkoutWithPayment";
import PayWall from "./paywall/PayWall";
import PdfPreview from "./PdfPreview/PdfPreview";
import RealisationTime from "./RealisationTime/RealisationTime";
import RequestModelConfirmation from "./RequestModelConfirmation/RequestModelConfirmation";
import SavedProjectUserDetailPopup from "./SavedProjectUserInfo/savedProjectUserDetail";
import SubSidy from "./SubSidy/SubSidy";
import ThreeDModel from "./ThreeDModel/ThreeDModel";

import { POPUP_KEYS, selectors as PopupSelectors } from "src/redux/popups";
import { openPopup } from "src/redux/popups/action/action.creators";
import { UserSelectors } from "src/redux/selectors";

const PopupRenderer: React.FC = () => {
  const popups = useSelector(PopupSelectors.getPopup);
  const location = useLocation();
  const dispatch = useDispatch();
  const [query] = useSearchParams();
  const installerDetails = useSelector(UserSelectors?.getInstaller);
  const installerSlug = query.get("partner");

  useEffect(() => {
    if (location.pathname.includes("webinar")) {
      const data = {
        title:
          "Please feel free to choose from our upcoming Events Your perfect start to your new energy system",
        heading: "SolarHub Solarwebinar",
        button_text: "REGISTER",
        extraContent: "",
      };
      dispatch(openPopup(POPUP_KEYS.webinar, data));
    }
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (
      !location.pathname.includes("default") &&
      !location.pathname.includes("saved") &&
      !location.pathname.includes("useroverview") &&
      !location.pathname.includes("payment") &&
      !location.pathname.includes("webinar") &&
      !location.pathname.startsWith("/pdf")
    ) {
      dispatch(openPopup(POPUP_KEYS.welcome_address));
    }
  }, [dispatch, location]);

  return (
    <>
      {popups.SAVED_PROJECT_USER_INFO.open && <SavedProjectUserDetailPopup />}
      {popups.AUTH_POPUP.open && <AuthPopup></AuthPopup>}
      {popups.WELCOME_ADDRESS.open && <WelcomePopupNoAddress></WelcomePopupNoAddress>}
      {popups.CONSUMPTION_CHARTS.open && <ChartTabs />}
      {popups.PRODUCT_POPUP.open && <ProductPopup />}
      {popups.CONSUMPTION_PROFILE_DIALOG.open && <ConsumptionProfileDialog />}
      {popups.SELECT_BUILDING_TYPE.open && <SelectBuildingType />}
      {popups.HUBSPOT_MEETING.open && !installerDetails && !installerSlug && <HubSpotBookCall />}
      {popups.INSTALLER_CONFIRMATION.open && <InstallerRequestSuccessfull />}
      {popups.DATA_POLICY.open && <DataPolicy />}
      {popups.PARTNER_CITY_CONSULTINNG.open && <PartnerCityConsulting />}
      {popups.WEBINAR_POPUP.open && <WebinarPopup />}
      {popups.WEBINAR_PROJECT_DETAILS.open && <WebinarProjectDetails />}
      {popups.PRODUCT_INFO.open && <ProductInfoPopup />}
      {popups.PRODUCT_DETAILS_POPUP.open && <ProductDetails />}
      {popups.PAY_WALL.open && <PayWall></PayWall>}
      {popups.CAR_SELECTOR.open && <CarSelector />}
      {popups.REQUEST_PV_MODEL.open && <RequestModelConfirmation />}
      {popups.MULTI_TENANT.open && <MultiTenant />}
      {popups.INSTALLER_OFFER.open && <DetailedOffer />}
      {popups.SAVED_PROJECT_USER_INFO.open && <SavedProjectUserDetailPopup />}
      {popups.SUBSIDY.open && <SubSidy />}
      {popups.PDF_PREVIEW.open && <PdfPreview />}
      {popups.THREEDMODEL.open && <ThreeDModel />}
      {popups.PDF_TEMPLATE_SELECTION.open && <PdfTemplateSelection />}
      {popups.REALISATIONTIME.open && <RealisationTime data={popups.REALISATIONTIME.data} />}
      <Elements stripe={stripeLoader}>
        <Suspense fallback={<ApplicationLoader />}>
          {popups.PAYMENT_WITH_CARD.open && <CheckoutWithPayment></CheckoutWithPayment>}
        </Suspense>
      </Elements>
    </>
  );
};

export default PopupRenderer;
